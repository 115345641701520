.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 100%;
    background-color: #222222;
}

.messages-container {
    width: 100%;
    background-color: #222222;
    border: 1px solid #ccc;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
}

.message {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    word-wrap: break-word;
}

.my-message {
    background-color: transparent;
    align-self: flex-end;
    text-align: right;
    justify-content: flex-end;
}

.their-message-int {
    background-color: #666666;
    align-self: flex-start;
    display: inline;
    padding: 5px;
    margin-bottom: 0px;
    border-radius: 10px;
    text-align: left;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
    word-wrap: break-word;
}

.my-message-int {
    background-color: #1982fc;
    align-self: flex-end;
    display: inline;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    text-align: right;
    font-size: 16px;
    color: #ffffff;
    max-width: 100%;
    word-wrap: break-word;
}

.their-message {
    background-color: transparent;
    align-self: flex-start;
    text-align: left;
}

.chat-input-container {
    display: flex;
    width: 100%;
    background-color: #222222;
}

textarea {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    overflow: hidden;
    min-height: 20px;
    resize: none;
    color: white;
    background-color: #222222;
    font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Helvetica Neue', Helvetica, Ubuntu, Roboto, Noto,
        'Segoe UI', Arial, sans-serif;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: rgba(80, 80, 189, 0.733);
}

.general-message-int {
    background-color: #42798a;
    align-self: flex-start;
    display: inline;
    padding: 5px;
    margin-bottom: 0px;
    border-radius: 10px;
    text-align: left;
    font-size: 16px;
    color: #ffffff;

    max-width: 100%; /* setting a max width will prevent a single message from taking full width */
    word-wrap: break-word;
}

.typing-indicator {
    display: flex;
    align-self: flex-start;
    padding-bottom: 10px;
}

.typing-indicator span {
    display: inline-block;
    align-self: flex-start;
    text-align: left;
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    margin: 0 2px;
    border-radius: 50%;
    animation: typingDots 1s infinite;
    opacity: 0;
}

.typing-indicator span:nth-child(1) {
    animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.33s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.66s;
}

@keyframes typingDots {
    0%,
    100% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
}
