@font-face {
    font-family: 'valizas';
    src: url('src/assets/fonts/ValizasVariable-Regular.ttf') format('truetype');
}

.greymattersHeaderName {
    && {
        color: var(--white, #fff);
        font-family: valizas;
        font-size: 60px;
        font-style: normal;
        font-weight: 650;
        line-height: normal;
    }
}

.greymattersLogo {
    && {
        color: var(--white, #fff);
        font-family: valizas;
        font-size: 15px;
        font-style: normal;
        font-weight: 650;
        line-height: normal;
    }
}

.greymattersMain {
    && {
        color: var(--White, #fff);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        /* font-family: Inter; */
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 64px */

        @media (max-width: 900px) {
            font-size: 34px;
        }
    }
}

.cardsTitle {
    && {
        font-size: 2rem;
        width: 50%;

        @media (max-width: 600px) {
            font-size: 1rem;
            width: 100%;
        }
    }
}

.animatedSlider .MuiSlider-thumb {
    transition: left 1s ease-in-out;
}
