.cardContainer {
    width: calc(60vh * 3 / 4); /* 20% of viewport height times the aspect ratio (width) */
    height: 60vh; /* 20% of the viewport height */
    position: relative;

    @media (max-width: 600px) {
        height: 60vh;
    }
}

.cardFace {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.6s !important;
}

.cardBack {
    background-color: #eee;
    transform: rotateY(180deg);
}

.cardContainer.flipped .cardFront {
    transform: rotateY(180deg);
}

.cardContainer.flipped .cardBack {
    transform: rotateY(0deg);
}
