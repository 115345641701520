/* Ensure the specificity is higher than any other potential rules by using the quill class too */
.quill-editor-container.toolbar-hidden .ql-toolbar.ql-snow {
    display: none !important;
}

.quill-editor-container.toolbar-hidden .ql-snow {
    border: none;
}

.quill-editor-container.toolbar-visible .ql-toolbar.ql-snow {
    display: block !important; /* Use important to override inline styles if necessary */
    border: 1px solid #ccc;
}

.comment-quill .ql-container {
    border: none;
}

.comment-quill .ql-editor {
    background-color: transparent;
    padding: 0;
}
